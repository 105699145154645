import React, { useEffect } from 'react'
// import parse from 'html-react-parser';

const Hero = ({ title, title2, video, location }) => {

  useEffect(() => {
    console.log(video)
  })

  const videoId = video.match(/vimeo\.com\/(\d+)/)?.[1];

  const embedUrl = `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&muted=1&autoplay=1&autopause=0&controls=1&dnt=1&loop=1&quality=1080`;


  return(
    <div className="project--hero-container grid" data-scroll-section>
      <div className="titles">
        <div>
          <h2 data-scroll data-scroll-speed="1" data-sal='fade' data-sal-delay='350' data-sal-duration='750' data-sal-easing='ease'>{title}&nbsp;</h2>
          <h2 data-scroll data-scroll-speed="1" data-sal='fade' data-sal-delay='350' data-sal-duration='750' data-sal-easing='ease'>{title2}</h2>
        </div>
        <p data-scroll data-scroll-speed="2" data-sal='fade' data-sal-delay='350' data-sal-duration='750' data-sal-easing='ease'>{location}</p>
      </div>
      <div className="video-container">
        {/* {parse(video)} */}
        <iframe
          src={embedUrl}
          width="980"
          height="419"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title={"Gutiérrez F Studio"}
        ></iframe>
      </div>
    </div>
  )
}

export default Hero
